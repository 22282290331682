.footerText {
    border-top: solid 1px rgb(219, 219, 219);
    width: 100%;
    margin: 0px auto;
    text-align: center;
    padding: 25px;
    text-align: center;
    background-color: #EEEEEE;
  }
.phoneNumber{
    text-decoration: none;
    color: #29a9c9;
}

