.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    border-bottom: 1px solid rgb(218, 218, 218);
    margin-right: 30px;
    /* background-color: #1976d2; */
}
.subHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    border-bottom: 1px solid rgb(218, 218, 218);
    margin-right: 30px;
    margin-left: 30px;
    /* background-color: #1976d2; */
}

.header2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    border-bottom: 1px solid rgb(218, 218, 218);
    padding-left: 20px;
    /* background-color: #1976d2; */
}

.logo {
    width: 70px;
    height: 70px;
}

.login {
    border: 1px solid rgb(57, 199, 300);
    padding: 12px 30px;
    border-radius: 50px;
    background-color: white;
    color: rgb(51, 51, 216);
    cursor: pointer;
    font-size: 15px;
    outline: none;
    margin-right: 20px;
    /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
}

.login:hover {
    background-color: rgb(209, 233, 255);
}

.menuLink{
    display: inline-block;
    padding-left: 20px;
    padding-right: 20px;
    color:#ffffff;
    text-decoration: none;
    height: 100%;
    line-height: 60px;
}
.subMenuLink{
    display: inline-block;
    padding-left: 20px;
    padding-right: 20px;
    color:#2a484e;
    text-decoration: none;
    width: 100%;
    display: block;
    min-width: 150px;
    direction: rtl;
}
.subMenuLink:hover,.menuLink:hover {
background-color: #b200df;
}