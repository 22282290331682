.pageLayout {
    margin: 10px;
}

.customInputs {
    width: 49%;
    padding: 5px;
    background: #f2f3f5;
    font-size: 11px;
    color: #8698a9;
    min-height: 80px;
    max-height: 80px;
}

.floatRTL {
    float: right;
    text-align: right;
}

.floatLTR {
    float: left;
    text-align: left;
}

.item {
    width: 150px;
    height: 150px;
    margin: 10px;
    /*border: 1px solid red;*/
    padding: 5px;
    text-align: center;
    border-radius: 5px;
    background-color: #ebdaff;
    flex-grow: 1;
    flex-shrink: 1;
}

.itemPreview {
    margin: 10px;
    /*border: 1px solid red;*/
    padding: 5px;

    border-radius: 5px;
    background-color: #fed7ff;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-flow: wrap;
    min-height: 90px;
    vertical-align: middle;
    min-width: 450px;
}

.itemPreviewData {
    width: 75%;
    font-size: 20px;
    vertical-align: middle;
}

.totalPriceBox {
    margin: 10px;
    padding: 5px;
    border-radius: 5px;
    background-color: #f165f5;
    font-size: 35px;
    vertical-align: middle;
    text-align: center;
    color: #ffffff;
    min-width: 450px;
}

.itemPrice {
    display: block;
    color: #c120bc;
    clear: both;
}

.itemName {
    display: block;
    color: #2a484e;
    font-size: 14px;
    clear: both;
}

.flexgrid {
    display: flex;
    flex-flow: wrap;
    align-content: space-around;
    flex-basis: 150px;
}

.cardContainer {
    padding: 20px;
    min-width: 450px;
    width: 450px;
}

.cardReceipt {
    overflow-y: auto;
    overflow-x: hidden;

}

.card {
    height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    min-width: 450px;
    width: 450px;
}

.cardReceipt table tr td,.card table tr td {
    font-size: 14px
}

.cardItem {
    width: 100%;
    margin: 10px;
    /*border: 1px solid red;*/
    padding: 5px;
    text-align: center;
    border-radius: 5px;
    background-color: #1796e8;
}

.noSelect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                     supported by Chrome and Opera */
}

::-webkit-scrollbar-track-piece {
    background-color: #fff;
    -webkit-border-radius: 0;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-thumb {
    height: 50px;
    background-color: #6DB4FB;
    -webkit-border-radius: 4px;
    outline: 2px solid #fff;
    outline-offset: -2px;
    border: 2px solid #fff;
}

::-webkit-scrollbar-thumb:hover {
    height: 50px;
    background-color: #9f9f9f;
    -webkit-border-radius: 4px;
}

.printOnly {
    display: none;
}

.printOnlyRow {
    display: none;
}

.np {
}
.hide{
    display: none;
}
.logo{
    max-width: 30mm;
    margin-left: auto;
    margin-right: auto;
    display: block;
    clear: both;
}
@media print {
    html, body {
        /*zoom: 80%;*/

    }

    nav, button,.button , select, .item, input ,fieldset, label, .MuiFormControl-root {
        display: none !important;
    }

    .MuiContainer-root {
        width: 100% !important;
        margin: 0 !important;
    }

    div {
        margin: 0px !important;
        padding: 0px !important;
    }

    .cardReceipt , .card {
        display: block;
        padding: 0 !important;
        margin: 5mm !important;
        max-width: 70mm;

    }
    .cardReceipt table tr td , .card table tr td {
        font-size: 10px !important;
        border: 0  !important;
        background: #ffffff;
    }
    html, body {
        width: 80mm;
        height: auto;
        padding: 0;
        margin: 0;
    }



    body {
        margin: 0;
    }

    #card {
        margin: 1mm !important;
        padding: 1mm !important;
    }



    .printOnlyRow {
        display: table-row;
    }

    .printOnly {
        display: inherit;
    }

    .np {
        display: none !important;
    }

.cardReceipt, .card {
        padding: 10px;
        height: unset;
        overflow-y: auto;
        overflow-x: auto;
        min-width: unset;
        width: unset;
    }
    .cardContainer {
        padding: 0;
        min-width: 80mm;
        width: 80mm;
    }
}

