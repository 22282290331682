.pageLayout {
    margin-top: 60px;
}

.customInputs {
    width: 49%;
    padding: 5px;
    font-size: 11px;
    color: #8698a9;
    min-height: 80px;
    max-height: 80px;
}

.floatRTL {
    float: right;
    text-align: right;
}

.floatLTR {
    float: left;
    text-align: left;
}